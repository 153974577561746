<template>
  <div>
    <Header></Header>
    <!-- <div class="xwdiv1">
       <img class="xwdiv1img1" src="../../images/xwzx/1.jpg" alt="">
      </div> -->

    <div class="block" width="100%;height:100%">
      <el-carousel :interval="5000">
        <el-carousel-item v-for="(items, index) in items" :key="index">
          <div
            style="
              color: #000000;
              text-align: center;
              overflow: hidden;
              width: 100%;
            "
          >
            <video
              style="width: 100%; height: auto"
              class="bannertt"
              v-if="items.type === 'video'"
              :src="items.url"
              autoplay
              muted
              loop
            ></video>
            <img
              style="width: 100%; height: auto"
              class="bannertt"
              v-else
              :src="items.url"
              alt="Image"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="listtop1" id="listtop11">
      <ul class="listul">
        <li
          @click="listtop1()"
          :class="listtop1s === true ? 'sy2listulli' : ''"
        >
          品牌资讯
        </li>
        <li
          @click="listtop2()"
          :class="listtop2s === true ? 'sy2listulli' : ''"
        >
          活动资讯
        </li>
        <li
          @click="listtop3()"
          :class="listtop3s === true ? 'sy2listulli' : ''"
        >
          产品资讯
        </li>
      </ul>

      <div :class="listtop1s === true ? ' ' : 'listdatanone'">
        <div class="listdata">
          <div
            @click="content(value.id)"
            v-for="(value, index) in list"
            :key="index"
            class="listdatadiv"
          >
            <img :src="'https://www.d-shang.com' + value.thumb" alt="" />
            <!-- <span>{{value.title}}</span>
                <a href=""></a>
                <a href="">{{ value.subtitle}}</a> -->
            <div class="listdatadiv2">
              <span>{{ value.title }}</span>
              <!-- <a href="">{{value.date}}</a> -->
              <a class="a1" href="">{{ value.subtitle }}</a>
              <div class="listdatadiv21">{{ value.date }}</div>
              <div class="listdatadiv22">
                <span>了解更多</span>
                <img
                  class="listdatadiv22img"
                  src="../../images/xwzx/jt1.png"
                  alt=""
                />
                <img
                  class="listdatadiv22img2"
                  src="../../images/xwzx/jt2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          @current-change="listtop1"
          :page-count="pagecount"
          :current-page.sync="p"
          background
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>

      <div :class="listtop2s === true ? ' ' : 'listdatanone'">
        <div class="listdata">
          <div
            @click="content(value.id)"
            v-for="(value, index) in list"
            :key="index"
            class="listdatadiv"
          >
            <img :src="'https://www.d-shang.com' + value.thumb" alt="" />
            <!-- <span>{{value.title}}</span>
                <a href=""></a>
                <a href="">{{ value.subtitle}}</a> -->
            <div class="listdatadiv2">
              <span>{{ value.title }}</span>
              <!-- <a href="">{{value.date}}</a> -->
              <a class="a1" href="">{{ value.subtitle }}</a>
              <div class="listdatadiv21">{{ value.date }}</div>
              <div class="listdatadiv22">
                <span>了解更多</span>
                <img
                  class="listdatadiv22img"
                  src="../../images/xwzx/jt1.png"
                  alt=""
                />
                <img
                  class="listdatadiv22img2"
                  src="../../images/xwzx/jt2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          @current-change="listtop2"
          :page-count="pagecount"
          :current-page.sync="p"
          background
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>

      <div :class="listtop3s === true ? ' ' : 'listdatanone'">
        <div class="listdata">
          <div
            @click="content(value.id)"
            v-for="(value, index) in list"
            :key="index"
            class="listdatadiv"
          >
            <img :src="'https://www.d-shang.com' + value.thumb" alt="" />
            <!-- <span>{{value.title}}</span>
                <a href=""></a>
                <a href="">{{ value.subtitle}}</a> -->
            <div class="listdatadiv2">
              <span>{{ value.title }}</span>
              <!-- <a href="">{{value.date}}</a> -->
              <a class="a1" href="">{{ value.subtitle }}</a>
              <div class="listdatadiv21">{{ value.date }}</div>
              <div class="listdatadiv22">
                <span>了解更多</span>
                <img
                  class="listdatadiv22img"
                  src="../../images/xwzx/jt1.png"
                  alt=""
                />
                <img
                  class="listdatadiv22img2"
                  src="../../images/xwzx/jt2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          @current-change="listtop3"
          :page-count="pagecount"
          :current-page.sync="p"
          background
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/HoMe.vue";
import Footer from "@/components/Footer.vue";
import img1 from "../../images/xwzx/1s.jpg";
import img2 from "../../images/xwzx/4.jpg";
import img6 from "../../images/xwzx/4s.jpg";
import img7 from "../../images/xwzx/5.jpg";
import img3 from "../../images/xwzx/3.jpg";
import img4 from "../../images/xwzx/2.jpg";
export default {
  name: "News",
  data() {
    return {
      listtop1s: true,
      listtop2s: false,
      listtop3s: false,
      p: 1,
      list: [],
      pagecount: 1,
      items: [
        { id: 1, type: "image", url: img4 },
        { id: 2, type: "image", url: img2 },
        { id: 3, type: "image", url: img6 },
        { id: 4, type: "image", url: img7 },
        { id: 5, type: "image", url: img1 },
        { id: 6, type: "image", url: img3 },
      ],
    };
  },

  methods: {
    content(value) {
      this.$router.push({
        name: "NewsContent",
        query: { id: value },
      });
    },

    listtop1() {
      var that = this;
      this.listtop1s = true;
      this.listtop2s = false;
      this.listtop3s = false;

      this.$axios
        .get(
          "https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2268&p=" +
            this.p +
            "&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw"
        )
        .then(function (res) {
          // console.log(res.data.data);
          that.list = res.data.data;
          console.log(that.list);
          // let date=[];
          let dayjs = require("dayjs");
          for (let i = 0; i < that.list.length; i++) {
            let dates = dayjs(that.list[i].date * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            console.log(dates);
            that.list[i].date = dates;
            //  date.push(dates)
          }
          // console.log(date,2222);
          console.log(that.list);
          that.pagecount = res.data.message.page;
        });
    },
    listtop2() {
      var that = this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = true;
      this.listtop3s = false;

      this.$axios
        .get(
          "https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2&p=" +
            this.p +
            "&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw"
        )
        .then(function (res) {
          // console.log(res.data.data);
          that.list = res.data.data;
          console.log(that.list);
          // let date=[];
          let dayjs = require("dayjs");
          for (let i = 0; i < that.list.length; i++) {
            let dates = dayjs(that.list[i].date * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            console.log(dates);
            that.list[i].date = dates;
            //  date.push(dates)
          }
          // console.log(date,2222);
          console.log(that.list);
          that.pagecount = res.data.message.page;
        });
    },
    listtop3() {
      var that = this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = true;

      this.$axios
        .get(
          "https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2269&p=" +
            this.p +
            "&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw"
        )
        .then(function (res) {
          // console.log(res.data.data);
          that.list = res.data.data;
          console.log(that.list);
          // let date=[];
          let dayjs = require("dayjs");
          for (let i = 0; i < that.list.length; i++) {
            let dates = dayjs(that.list[i].date * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            console.log(dates);
            that.list[i].date = dates;
            //  date.push(dates)
          }
          // console.log(date,2222);
          console.log(that.list);
          that.pagecount = res.data.message.page;
        });
    },
  },

  components: {
    Header,
    Footer,
  },

  mounted() {
    this.listtop1();
  },
};
</script>

<style lang="stylus" scoped>
.el-carousel {
  // width: 500px;
  height: 531px;
  overflow: hidden;

  .el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
  }

  /deep/ .el-carousel__indicators { // 指示器
    left: unset;
    transform: unset;
    right: 48%;
    bottom: -50px;
  }

  /deep/ .el-carousel__button { // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 1;
  }

  /deep/ .is-active .el-carousel__button { // 指示器激活按钮
    background: #cbad65;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }
}

/* .el-carousel__containe /deep/{
    background: red;
    height:100%;
} */
/* .el-carousel /deep/{
      background: blue;
      height:100%;
  }

.el-carousel__container /deep/{
    height: 100%;
    position: none;
} */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
  margin: 0;
  padding: 0;
}

.hide {
  opacity: 0;
}

a {
  text-decoration: none;
}

.block {
  margin-top: 100px;
}

.xwdiv1img1 {
  width: 100%;
  height: 531px;
  display: flex;
  margin-top: 87px;
}

.listtopnone {
  display: none;
}

.custom-class {
  --el-tag-bg-color: red;
}

.listdatanone {
  display: none;
}

.listdatadiv span {
  font-size: 20px;
  color: #000;
  display: inline-block;
  margin-top: 35px;
  margin-left: 30px;
  height: 58px;
}

.listdatadiv a {
  display: block;
  font-size: 12px;
  color: #a9a6a6;
  margin-top: 14px;
  margin-left: 30px;
}

.listdatadiv img {
  width: 356px;
  height: 237px;
  display: inline-block;
  float: left;
}

.listdata {
  width: 108.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.listdatadiv21 {
  color: #a9a6a6;
  margin-top: 50px;
  margin-left: 30px;
  float: left;
  font-size: 12px;
}

.listdatadiv22 {
  color: #a9a6a6;
  margin-top: 13px;
  float: right;
  margin-right: 10px;
}

.listdatadiv22 .listdatadiv22img {
  height: 20px;
  width: 20px;
  margin-top: 36px;
}

.listdatadiv22 .listdatadiv22img2 {
  height: 20px;
  width: 20px;
  margin-top: 36px;
  display: none;
}

.listdatadiv22 span {
  float: left;
  margin-right: 12px;
  font-size: 15px;
}

.a1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}

.listdata .listdatadiv2 {
  float: left;
  width: 320px;
  height: 237px;
  // background:red;
  text-align: left;
}

.listdata .listdatadiv {
  // flex: 1;
  // width: 356px;
  height: 237px;
  margin: 0 74px 45px 0;
  // background-color: #f2f2f2;
  // width: calc(((100% - 84px) / 2)); // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
  // min-width: calc(((100% - 84px) / 2)); // 加入min-width、max-width后每个child的宽度就生效了
  // max-width: calc(((100% - 84px) / 2));
  cursor: pointer;
}

.listdatadiv:hover .listdatadiv2 span {
  color: #cbad65;
}

.listdatadiv:hover .listdatadiv2 .listdatadiv22img {
  display: none;
}

.listdatadiv:hover .listdatadiv2 .listdatadiv22img2 {
  display: inline-block;
}

.listtop1 {
  margin-top: 60px;
  width: 74.5%;
  margin-left: 250px;
  margin-bottom: 70px;
}

.listtop2 {
  margin-top: 60px;
  width: 74.5%;
  margin-left: 250px;
}

.listul li {
  color: #000;
  width: 77px;
  margin: 0;
  height: 40px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 100px;
  font-weight: 700;
}

.sy2listulli {
  color: #000;
  width: 287px;
  margin: 0;
  height: 40px;
  border-bottom: 4px solid #ccad65;
}

.listul {
  height: 40px;
  border-bottom: 4px solid #e3e3e3;
  margin-bottom: 45px;
  display: flex;
  justify-content: left;
}

.sy2ulbh {
  display: inline-block;
}

.sy2ulbhs {
  display: none;
}

.mlbh {
  display: none !important;
}

.bh img {
  hieght: 700px;
  width: 100%;
  display: block;
}

ul li img {
  width: 78px;
  height: 81px;
}

ul li {
  margin-top: 20px;
  width: 277px;
  padding: 0px;
  margin-left: 105px;
}

ul {
  display: flex;
  justify-content: center;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
}
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #cbad65 !important;
  color: #fff;
}
</style>

